<template>
  <mcr-button class="book-call-button" @click="bookCall">{{ label }}</mcr-button>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {initCalendlyScript} from '@common/utils/script.loader';

export default {
  props: {
    email: String,
    url: {type: String, default: ''},
  },
  created() {
    initCalendlyScript();
  },
  computed: {
    label() {
      return 'Schedule a Call';
    },
  },
  methods: {
    bookCall() {
      const name = this.$store.getters.userIsLoggedInState ? this.$store.getters.userFullNameState : '';
      const prefill = {email: this.email, name: name};
      Calendly.initPopupWidget({url: this.url || process.env.VUE_APP_CALENDLY_UPDATE_PR_URL, prefill});

      AnalyticsAmplitudeHandler.trackClickContactButton(this.label, false, getRoutePageName(this.$route), true);
    },
  },
  components: {McrButton},
};
</script>

<style lang="scss" scoped>
.mcr-button.book-call-button {
  background-color: $mcr-dark-tan;

  &:hover {
    background-color: $mcr-tan;
  }
}
</style>
